import React from "react";
import { Layout } from "antd";
import { navigate } from "gatsby";

import styled from "styled-components";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { makeStyles } from "@mui/styles";

// import { URLS } from "@helpers/const";
import { color, mobileMenu, URLS } from "@helpers/const";
import { isLoggedIn } from "@services/auth";
import withLocation from "@helpers/hoc/withLocation";

// import logo from "@images/logos/logo.svg";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    bottom: "0px",
    left: "0px",
    right: "0px",
    marginBottom: "0px",
    width: "100vw",
  },
});

const AntFooter = Layout.Footer;

type WithThisUser<T = {}> = T & { children?: React.ReactNode };

type FooterProps = WithThisUser<{
  userDetails: {
    name?: string;
    avatarUrl?: string;
  };
  location: object;
}>;

const Footer: React.FC<FooterProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userDetails,
  location,
}: FooterProps) => {
  const [value, setValue] = React.useState(location.pathname);
  const classes = useStyles();

  return (
    <FooterWrapper>
      <AntFooter
        style={{
          textAlign: "center",
          position: "fixed",
          width: "100%",
          transition: "bottom 0.2s",
          padding: "0",
          zIndex: "1000",
          boxShadow:
            "rgb(136 136 136) 0px 25px 1px, rgb(136 136 136) 0px 4px 20px",
        }}
      >
        <Box
          sx={{
            "& .Mui-selected, .Mui-selected > svg": {
              color: color.whiteColor,
              backgroundColor: color.primary,
            },
            ".MuiButtonBase-root": {
              color: color.primary,
            },
            ".MuiBottomNavigation-root": {
              height: "80px",
            },
            "MuiSvgIcon-root": {
              fontSize: "2.0rem",
            },
          }}
        >
          <BottomNavigation
            showLabels
            className={classes.root}
            value={value}
            onChange={(event, url) => {
              setValue(url);
              navigate(url);
            }}
          >
            {mobileMenu?.map(
              ({ text, Icon, url, logedInText, logedInURL }, index) => {
                if (url === URLS.login && isLoggedIn()) {
                  return (
                    <BottomNavigationAction
                      key={index}
                      label={logedInText}
                      value={logedInURL}
                      icon={<Icon />}
                    />
                  );
                }
                return (
                  <BottomNavigationAction
                    key={index}
                    label={text}
                    value={url}
                    icon={<Icon />}
                  />
                );
              }
            )}
          </BottomNavigation>
        </Box>
      </AntFooter>
    </FooterWrapper>
  );
};

export default withLocation(Footer);

const FooterWrapper = styled.div`
  /* && {
    margin: 0 auto;
    padding-left: 10%;
    padding-right: 10%;
    height: 100%;
  }

  .welcomeRow {
    padding: 10px;
  } */
`;
