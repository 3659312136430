import React from "react";
import { Divider, Table, Input, Select, Pagination, Form, Button } from "antd";
import { navigate } from "gatsby";
import { parseQueryString, generateQueryString } from "@helpers/tools";
import { URLS, selectOpts } from "@helpers/const";

import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import SEO from "@components/common/seo";
import { PageHeader } from "@components/common/pageHeader";
import { queries } from "@helpers/queryStrings";

// import { getUser } from "@services/auth";
import withLocation from "@helpers/hoc/withLocation";

type Props = {
  search?: {
    search: string;
  };
  location: object;
};

const Home = ({ search }: Props) => {
  const [form] = Form.useForm();
  const [insightQuery, setInsightQuery] = React.useState({});
  const [pageSize, setPageSize] = React.useState(10);

  const [exeInsights, { data: insightsData, loading: insightsLoading }] =
    useLazyQuery(queries.insights, {
      variables: insightQuery,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

  const processKey = data => {
    return data
      ? data?.map((res, index) => ({
          ...res,
          row_num: index,
          key: index,
        }))
      : [];
  };

  

  React.useEffect(() => {
    exeInsights();
  }, []);

  React.useEffect(() => {
    setInsightQuery(search);
  }, [search]);

  return (
    <HomeWrapper>
      <SEO title="Home" />
      <PageHeader title="Commlabs Insight" />
      <div className="mx-10">
        <div>Search Balance: {insightsData?.insights?.data?.userBalance|| 0}</div>
        <Form
          form={form}
          onFinish={(values: any) =>
            setInsightQuery({ [values.field]: values.input })
          }
          autoComplete="off"
        >
          <div className="mb-2 mr-2">Search by:</div>
          <Form.Item name="field" initialValue={"name"}>
            <Select className="w-full">
              {selectOpts.map((res, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={res.value}
                    disabled={res.disabled}
                  >
                    {res.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="input"
            rules={[
              { required: true, message: "Please key your seatch input!" },
            ]}
          >
            <Input placeholder="search insights..." />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
            <Button
              block
              className="mt-2"
              onClick={() => {
                form.resetFields();
                navigate(`${URLS.home}`);
              }}
            >
              Clear Filter
            </Button>
          </Form.Item>
        </Form>

        <Divider orientation="left" className="text-slate-300">
          results (
          {insightsData
            ? insightsData?.insights?.data?.totalInsights
            : "loading..."}
          )
        </Divider>
        <Table
          scroll={{ x: 1500 }}
          // scroll={{ x: 1500, y: 300 }}
          pagination={false}
          loading={insightsLoading}
          columns={[
            {
              title: "ID",
              dataIndex: "detail_id",
              key: "detail_id",
              fixed: "left",
              width: 50,
              // render: (_, record) => (
              //   <Tooltip title={record.detail_id}>
              //     <span className="w-1 cml-elipsis">{record.detail_id}</span>
              //   </Tooltip>
              // ),
            },
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
              width: 150,
              render: (_, record) => (
                <a
                  onClick={() => {
                    navigate(
                      `${URLS.home}?${generateQueryString({
                        ...parseQueryString(location.search),
                        name: record.name,
                      })}`
                    );
                  }}
                >
                  {record.name}
                </a>
              ),
            },

            {
              title: "New IC",
              dataIndex: "new_ic",
              key: "new_ic",
              width: 150,
              render: (_, record) => (
                <a
                  onClick={() => {
                    navigate(
                      `${URLS.home}?${generateQueryString({
                        ...parseQueryString(location.search),
                        new_ic: record.new_ic,
                      })}`
                    );
                  }}
                >
                  {record.new_ic}
                </a>
              ),
            },
            {
              title: "Old IC",
              dataIndex: "old_ic",
              key: "old_ic",
              width: 150,
            },

            {
              title: "Other IC",
              dataIndex: "other_ic",
              key: "other_ic",
              width: 150,
            },
            {
              title: "Contact No",
              dataIndex: "contact_no",
              key: "contact_no",
              width: 150,
              render: (_, record) => (
                <a
                  onClick={() => {
                    navigate(
                      `${URLS.home}?${generateQueryString({
                        ...parseQueryString(location.search),
                        contact_no: record.contact_no,
                      })}`
                    );
                  }}
                >
                  {record.contact_no}
                </a>
              ),
            },
            {
              title: "Contact No 2",
              dataIndex: "contact_no2",
              key: "contact_no2",
              width: 150,
            },
            {
              title: "Contact No 3",
              dataIndex: "contact_no3",
              key: "contact_no3",
              width: 150,
            },
            {
              title: "Address 1",
              dataIndex: "add_1",
              key: "add_1",
              width: 150,
            },

            {
              title: "Address 2",
              dataIndex: "add_2",
              key: "add_2",
              width: 150,
            },

            {
              title: "Address 3",
              dataIndex: "add_3",
              key: "add_3",
              width: 150,
            },
            {
              title: "Address 4",
              dataIndex: "add_4",
              key: "add_4",
              width: 150,
            },
            {
              title: "Address 5",
              dataIndex: "add_5",
              key: "add_5",
              width: 150,
            },
            {
              title: "Source",
              dataIndex: "source",
              key: "source",
              width: 150,
              render: (_, record) => <p>{record.source}</p>,
            },
            {
              title: "Bus Reg",
              dataIndex: "bus_reg",
              key: "bus_reg",
              width: 150,
            },

            {
              title: "City",
              dataIndex: "city",
              key: "city",
              width: 150,
            },

            {
              title: "Postcode",
              dataIndex: "postcode",
              key: "postcode",
              width: 150,
            },

            {
              title: "State",
              dataIndex: "state",
              key: "state",
              width: 150,
            },
            {
              title: "Country",
              dataIndex: "country",
              key: "country",
              width: 150,
            },

            {
              title: "Emp Name",
              dataIndex: "emp_name",
              key: "emp_name",
              width: 150,
            },
            {
              title: "Monthly Income",
              dataIndex: "monthly_income",
              key: "monthly_income",
              width: 150,
            },
            {
              title: "Race",
              dataIndex: "race",
              key: "race",
              width: 150,
            },
            {
              title: "Occupation",
              dataIndex: "occupation",
              key: "occupation",
              width: 150,
            },
            {
              title: "Blood Type",
              dataIndex: "blood_type",
              key: "blood_type",
              width: 150,
            },
          ]}
          dataSource={processKey(insightsData?.insights?.data?.allInsights)}
        />
        <div className="my-3 text-center">
          <Pagination
            defaultCurrent={1}
            total={insightsData?.insights?.data?.totalInsights}
            pageSize={pageSize}
            // showSizeChanger={false}
            onChange={(page, _pageSize) => {
              console.log(page);
              console.log(_pageSize);

              setPageSize(_pageSize);
              const skip = (page - 1 > 0 ? page - 1 : 0) * _pageSize;
              setInsightQuery({
                ...insightQuery,
                skip,
                pageSize: _pageSize,
              });
              exeInsights();
            }}
          />
        </div>
      </div>
    </HomeWrapper>
  );
};

export default withLocation(Home);

const HomeWrapper = styled.div`
  && {
    margin: 0 auto;
  }
`;
