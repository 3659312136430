import React from "react";
import {
  Button,
  Row,
  Statistic,
  Modal,
  Select,
  Input,
  Form,
  DatePicker,
  Divider,
  Space,
} from "antd";
import type { InputRef } from "antd";
import { navigate } from "gatsby";
import { PageHeader as AntPageHeader } from "@ant-design/pro-layout";

import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";
import { capitalizeFirstLetter } from "@helpers/tools";
import { URLS } from "@helpers/const";
import { getUser } from "@services/auth";

type Props = {
  createTransaction?: Function;
  formDetailsData?: object;
  statisticTitle: object;
  title: string;
};

export const PageHeader: React.FC<Props> = ({
  createTransaction,
  formDetailsData,
  statisticTitle,
  title,
}: Props) => {
  const [isTransactModalVisible, setIsTransactModalVisible] =
    React.useState(false);
  const [interTransfer, setInterTransfer] = React.useState(false);
  const [form] = Form.useForm();
  const inputRef = React.useRef<InputRef>(null);
  const [items, setItems] = React.useState(["jack", "lucy"]);
  const [name, setName] = React.useState("");
  const { id } = getUser();

  return (
    <PageHeaderWrapper>
      <div className="mx-5">
        <AntPageHeader
          title={title}
          subTitle={
            <div>
              <EyeOutlined className="text" />
            </div>
          }
          // extra={[
          //   <Button
          //     key="1"
          //     type="primary"
          //     icon={<PlusOutlined />}
          //     shape="circle"
          //     onClick={() => setIsTransactModalVisible(!isTransactModalVisible)}
          //   ></Button>,
          //   <Button
          //     key="2"
          //     icon={<InteractionOutlined />}
          //     shape="circle"
          //     onClick={() => setInterTransfer(!isTransactModalVisible)}
          //   ></Button>,
          // ]}
        >
          {statisticTitle && (
            <Row>
              <Statistic
                title={statisticTitle.first}
                prefix="$"
                value={statisticTitle.firstAmt ? statisticTitle.firstAmt : 0}
              />
              <Statistic
                title={statisticTitle.second}
                prefix="$"
                value={statisticTitle.secondAmt ? statisticTitle.secondAmt : 0}
                style={{
                  margin: "0 32px",
                }}
              />
              <Statistic
                title={statisticTitle.third}
                prefix="$"
                value={statisticTitle.thirdAmt ? statisticTitle.thirdAmt : 0}
              />
            </Row>
          )}
        </AntPageHeader>
      </div>

      <Modal
        title="Add Transaction"
        open={isTransactModalVisible}
        footer={null}
        onCancel={() => setIsTransactModalVisible(false)}
      >
        <Form
          form={form}
          name="add_tran"
          layout="vertical"
          initialValues={{
            transaction_type: formDetailsData?.transactionTypes?.data?.[0]?.id,
            account: formDetailsData?.accounts?.data?.[0]?.id,
            transaction_tag: formDetailsData?.transactionTags?.data?.[0]?.id,
            date_time: moment(),
            amount: 0,
          }}
          onFinish={(values: any) => {
            values.user = id;
            createTransaction({
              variables: {
                data: values,
              },
            });
            setIsTransactModalVisible(false);
            navigate(URLS.ledgers);
          }}
          scrollToFirstError
        >
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Please input your amount!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="transaction_type"
            label="Transact Type"
            rules={[
              { required: true, message: "Please select transact type!" },
            ]}
          >
            <Select placeholder="select transact type">
              {formDetailsData?.transactionTypes?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="account"
            label="Account"
            rules={[{ required: true, message: "Please select Account!" }]}
          >
            <Select placeholder="select account">
              {formDetailsData?.accounts?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="transaction_tag"
            label="Tags"
            rules={[{ required: true, message: "Please select tags" }]}
          >
            <Select
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input
                      placeholder="Please enter item"
                      ref={inputRef}
                      value={name}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setName(event.target.value);
                      }}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        e.preventDefault();
                        setItems([...items, name || `New item ${index++}`]);
                        setName("");
                        setTimeout(() => {
                          inputRef.current?.focus();
                        }, 0);
                      }}
                    >
                      Add Tags
                    </Button>
                  </Space>
                </>
              )}
            >
              {formDetailsData?.transactionTags?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="date_time"
            label="Transact Period"
            rules={[{ required: true, message: "Please select datetime!" }]}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            />
          </Form.Item>

          <Form.Item name="remark" label="Remarks">
            <Input.TextArea showCount maxLength={100} />
          </Form.Item>

          {/* <Form.Item> */}
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
          {/* </Form.Item> */}
        </Form>
      </Modal>
      <Modal
        title="Inter Account Transfer"
        open={interTransfer}
        footer={null}
        onCancel={() => setInterTransfer(false)}
      >
        <Form
          form={form}
          name="inter_tran"
          layout="vertical"
          onFinish={(values: any) => {
            console.log("Received values of form: ", values);
            createTransaction({
              variables: {
                data: {
                  amount: values.transfer_amount,
                  transaction_type: 2,
                  account: values.from_account,
                  date_time: values.date_time,
                  transaction_tag: 3,
                  user: id,
                },
              },
            });
            createTransaction({
              variables: {
                data: {
                  amount: values.transfer_amount,
                  transaction_type: 1,
                  account: values.to_account,
                  date_time: values.date_time,
                  transaction_tag: 3,
                  user: id,
                },
              },
            });
            setInterTransfer(false);
            navigate(URLS.ledgers);
          }}
          scrollToFirstError
          initialValues={{
            transfer_amount: 0,
            transaction_charge: 0,
            from_account: formDetailsData?.accounts?.data?.[0]?.id,
            to_account: formDetailsData?.accounts?.data?.[1]?.id,
            date_time: moment(),
          }}
        >
          <Form.Item
            name="transfer_amount"
            label="Transfer Amount"
            rules={[
              {
                required: true,
                message: "Please input your Transfer Amount!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="transaction_charge"
            label="Transaction Charge"
            tooltip="bank charges for transaction"
            rules={[
              {
                required: true,
                message: "Please input your Transaction Charge!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="from_account"
            label="From"
            rules={[{ required: true, message: "Please select from account!" }]}
          >
            <Select placeholder="select account">
              {formDetailsData?.accounts?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="to_account"
            label="To"
            rules={[{ required: true, message: "Please select to account!" }]}
          >
            <Select placeholder="select account">
              {formDetailsData?.accounts?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="date_time"
            label="Transact Period"
            rules={[{ required: true, message: "Please select datetime!" }]}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            />
          </Form.Item>

          <Form.Item name="remark" label="Remarks">
            <Input.TextArea showCount maxLength={100} />
          </Form.Item>

          {/* <Form.Item> */}
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
          {/* </Form.Item> */}
        </Form>
      </Modal>
    </PageHeaderWrapper>
  );
};

const PageHeaderWrapper = styled.div``;
