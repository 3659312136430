import styled from "styled-components";

const padding = "30px";
export const Section = styled.div`
  margin-top: ${prop => (prop.withTBPadding ? padding : "0")};
  margin-left: ${prop => (prop.withLRPadding ? padding : "0")};
  margin-bottom: ${prop => (prop.withTBPadding ? padding : "0")};
  margin-right: ${prop => (prop.withLRPadding ? padding : "0")};
`;

const color = {
  blue_100: "#003E6F",
  white: "#FFFFFF",
};
export const token = {
  colorPrimary: color.blue_100,
  colorLink: color.blue_100,
  colorInfo: color.blue_100,
  colorInfoText: color.blue_100,
  colorPrimaryText: color.blue_100,
  colorBgLayout: color.white,
};
