import React, { useState, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Layout as AntLayout, Spin } from "antd";
import styled from "styled-components";
// import { Player } from "@lottiefiles/react-lottie-player";

import useBreakpoints from "@helpers/hooks/breakPoints";
import loader from "./loader.json";

import Footer from "./footer";
import "@styles/global.styles.css";

const { Content } = AntLayout;

const Layout: React.FC = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [footerVisible, setFooterVisible] = useState(true);
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const point = useBreakpoints();

  const breakMDBelow = point !== "md" && point !== "sm";

  useLayoutEffect(() => {
    window.addEventListener("scroll", () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollpos < currentScrollPos;

      setPrevScrollpos(currentScrollPos);
      setFooterVisible(visible);
    });
  }, [prevScrollpos]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <MainLoading
          spinning={isLoading}
          // indicator={
          //   <Player
          //     autoplay
          //     loop
          //     src={loader}
          //     style={{ height: "300px", width: "300px" }}
          //   ></Player>
          // }
        >
          <AntLayout style={{ overflow: "hidden" }}>
            {/* {breakMDBelow && (
              <Anchor style={{ padding: "0", background: "transparent" }}>
                <Header
                  siteTitle={data.site.siteMetadata.title}
                  menuExpand={menuExpand}
                  triggerMenu={(flag: boolean) => setMenuExpand(flag)}
                />
              </Anchor>
            )} */}
            <Content
              // style={{ paddingBottom: footerVisible ? "80px" : "0" }}
              style={{ paddingBottom: "80px" }}
            >
              <main>{children}</main>
            </Content>
            <Footer footerVisible={footerVisible} breakMDBelow={breakMDBelow} />
          </AntLayout>
        </MainLoading>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

const MainLoading = styled(Spin)`
  max-height: 98vh !important;

  .lf-player-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -153px;
  }
`;
