import { gql } from "@apollo/client";

export const queries = {
  me: gql`
    query me {
      me {
        id
        username
        email
        role {
          id
          type
          name
        }
      }
    }
  `,
  usersPermissionsUser: gql`
    query usersPermissionsUser($id: ID!) {
      usersPermissionsUser(id: $id) {
        data {
          id
          attributes {
            createdAt
            updatedAt
            username
            email
            provider
            confirmed
            blocked
            role {
              data {
                id
                attributes {
                  type
                  name
                }
              }
            }
          }
        }
      }
    }
  `,

  insights: gql`
    query insights(
      $detail_id: String
      $name: String
      $status: String
      $email: String
      $contact_no: String
      $contact_no2: String
      $contact_no3: String
      $gender: String
      $title: String
      $add_1: String
      $add_2: String
      $add_3: String
      $add_4: String
      $add_5: String
      $city: String
      $postcode: String
      $state: String
      $country: String
      $old_ic: String
      $new_ic: String
      $other_ic: String
      $bus_reg: String
      $batch: String
      $c_date: String
      $c_by: String
      $u_date: String
      $u_by: String
      $emp_name: String
      $monthly_income: String
      $race: String
      $occupation: String
      $blood_type: String
      $skip: number
      $pageSize: number
    ) {
      insights(
        detail_id: $detail_id
        name: $name
        status: $status
        email: $email
        contact_no: $contact_no
        contact_no2: $contact_no2
        contact_no3: $contact_no3
        gender: $gender
        title: $title
        add_1: $add_1
        add_2: $add_2
        add_3: $add_3
        add_4: $add_4
        add_5: $add_5
        city: $city
        postcode: $postcode
        state: $state
        country: $country
        old_ic: $old_ic
        new_ic: $new_ic
        other_ic: $other_ic
        bus_reg: $bus_reg
        batch: $batch
        c_date: $c_date
        c_by: $c_by
        u_date: $u_date
        u_by: $u_by
        emp_name: $emp_name
        monthly_income: $monthly_income
        race: $race
        occupation: $occupation
        blood_type: $blood_type
        skip: $skip
        pageSize: $pageSize
      ) @client {
        data
      }
    }
  `,
};

export const mutation = {
  login: gql`
    mutation login($input: UsersPermissionsLoginInput!) {
      login(input: $input) {
        jwt
        user {
          id
          username
        }
      }
    }
  `,
  register: gql`
    mutation register($input: UserInput!) {
      register(input: $input) {
        jwt
        user {
          id
          username
        }
      }
    }
  `,
  createTransaction: gql`
    mutation createTransaction($data: TransactionInput!) {
      createTransaction(data: $data) {
        data {
          id
        }
      }
    }
  `,
  createAccount: gql`
    mutation createAccount($data: AccountInput!) {
      createAccount(data: $data) {
        data {
          id
        }
      }
    }
  `,
  deleteTransaction: gql`
    mutation deleteTransaction($id: ID!) {
      deleteTransaction(id: $id) {
        data {
          id
        }
      }
    }
  `,
};
