import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { Row, Col, message } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import SEO from "@components/common/seo";
import LoginForm from "@components/common/loginForm";

import { handleLogin, isLoggedIn } from "@services/auth";
import { mutation, queries } from "@helpers/queryStrings";
import { color, URLS } from "@helpers/const";
import withLocation from "@helpers/hoc/withLocation";

import logo from "@images/logos/logo.svg";

type SubmitLoginProps = {
  identifier: String;
  password: String;
};
type AccessProps = {
  search?: {
    auth: string;
    token: string;
    provider: string;
  };
  location: object;
};

const Access: React.FC = ({
  search: { auth, token, provider },
}: AccessProps) => {
  const [login, loginData] = useMutation(mutation.login, {
    errorPolicy: "all",
  });

  const isSelection = auth ? auth : "login";

  useEffect(() => {
    const { data, error, loading } = loginData;
    if (!loading && data) {
      handleLogin(data);
      navigate(URLS.home);
    }

    if (error) {
      const errMsg = error.graphQLErrors
        ? error?.graphQLErrors[0]?.extensions?.exception?.data?.message[0]
            ?.messages[0]?.message
        : "Errors";
      message.error(errMsg);
    }
  }, [loginData]);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(URLS.login);
    }
  }, []);

  if (token && provider) {
    const { data, loading, error } = useQuery(queries.ssoLogin, {
      fetchPolicy: "no-cache",
      variables: {
        token,
        provider,
      },
    });

    if (error) {
      const errMsg = "Errors";
      message.error(errMsg);
    }
    console.log(error);

    if (!loading && data?.ssoLogin) {
      handleLogin(data);
      navigate(URLS.home);
    }
  }

  return (
    <>
      <SEO title="Login" />
      <AccessWrapper>
        <Row
          className="row"
          justify="center"
          align="middle"
          style={{ background: color.loginBack }}
        >
          <Col xs={24} md={24} style={{ padding: "60px" }}>
            <Logo />
            <LoginForm
              loading={loginData.loading}
              isSelection={isSelection}
              submitLogin={(values: SubmitLoginProps) =>
                login({
                  variables: {
                    input: {
                      identifier: values.email,
                      password: values.password,
                    },
                  },
                })
              }
            />
          </Col>
        </Row>
      </AccessWrapper>
    </>
  );
};

export default withLocation(Access);

const AccessWrapper = styled.div`
  .col-width {
    max-width: 340px;
    width: 340px;
    background: ${color.loginBack};
  }

  .ant-tabs-top {
    border-radius: 10px;
  }
`;

const Logo = styled.div`
  width: 100px;
  height: 100px;
  background: url(${logo});
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 20px auto;
`;
